var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"moderator-page__top",class:{
    'main-line': _vm.$route.name == 'ModeratorPublicationsArticle',
  }},[_c('h2',{staticClass:"moderator-page__title"},[_vm._v("Модерация")]),(_vm.$route.name != 'ModeratorPublicationsArticle')?_c('ModeratorSearch'):_vm._e(),(_vm.$route.name != 'ModeratorPublicationsArticle')?_c('div',{staticClass:"main-filter moderator-page__filter main-color"},[_c('router-link',{staticClass:"main-filter__button main-filter__button_long",class:{
        'main-filter__button_active': ~_vm.$route.name.indexOf(
          'ModeratorPublicationsTable'
        ),
      },attrs:{"to":{ name: 'ModeratorPublicationsTableNew' }}},[_vm._v(" Таблица ")]),_c('router-link',{staticClass:"main-filter__button main-filter__button_long",class:{
        'main-filter__button_active': ~_vm.$route.name.indexOf(
          'ModeratorPublicationsCalendar'
        ),
      },attrs:{"to":{ name: 'ModeratorPublicationsCalendarNew' }}},[_vm._v(" Календарь ")])],1):_vm._e(),(_vm.$route.name == 'ModeratorPublicationsArticle')?_c('div',{staticClass:"main-submit"},[_c('button',[_vm._v("Сохранить")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }